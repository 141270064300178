// See the scrollLock port
// import { lock, clearBodyLocks } from "tua-body-scroll-lock";
import { Socket } from "./js/socket.js";
import { Elm } from "./src/Main.elm";


function openSocket(app) {
  var loc = window.location;
  var wsaddress = loc.protocol.replace("http", "ws") + "//" + loc.hostname + (loc.port ? ":" + loc.port : "");

  var socket = new Socket({
    address: wsaddress,
    onMessage: function (message) {
      app.ports.listen.send({
        data: message.data,
        receivedAt: new Date().valueOf(),
      });
    },
    onConnection: function () {
      var jwt = window.localStorage.getItem("_jwt");

      if (jwt) {
        return JSON.stringify({ type: "establish", token: jwt });
      }
    },
  });

  socket.connect();

  app.ports.send.subscribe(function (message) {
    socket.send(message);
  });
}

function hash(str) {
  var hash = 5381;
  var i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  return hash >>> 0;
}

function loadApp() {
  // See the scrollLock port
  // clearBodyLocks();

  var app = Elm.Main.init({
    flags: [window.localStorage.getItem("_jwt"), window.location.origin],
  });

  openSocket(app);

  app.ports.authenticate.subscribe(function (encodedJwt) {
    let jwt = JSON.parse(encodedJwt);

    if (jwt) {
      window.localStorage.setItem("_jwt", jwt);
    } else {
      window.localStorage.removeItem("_jwt");
      window.location.href = "/logout";
    }
  });

  app.ports.scrollLock.subscribe(function (targetElement) {
    // If this and scrollUnlockAll are sent in the same update tick,
    // this needs to be guaranteed to run afterwards
    // Turned off for now in the hopes that we can just use
    // overscroll-behavior: contain; (83% browser support).
    // Leaving the infrastructure in place though.
    window.requestAnimationFrame(function () {
      // lock(targetElement);
    });
  });

  app.ports.scrollUnlockAll.subscribe(function () {
    // See the scrollLock port
    // clearBodyLocks();
  });

  app.ports.copyToClipboard.subscribe(function (text) {
    navigator.clipboard.writeText(text);
    // todo: give feedback on copy success/failure
  });
}

window.addEventListener("DOMContentLoaded", () => {
  loadApp();
});
