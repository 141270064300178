export class Socket {
  static MIN_TIMEOUT = 250;
  static MAX_TIMEOUT = 10000;
  static SOCKET_DOWN_ERR = "InvalidStateError";

  constructor({ address, onMessage, onConnection }) {
    // There could also be a callback for when websocket state changes
    // so we can show indicators in the UI
    this.address = address;
    this.onMessage = onMessage;
    this.onConnection = onConnection;
    this.timeout = Socket.MIN_TIMEOUT;
    this.outgoing = [];
  }

  socketConnected() {
    this.timeout = Socket.MIN_TIMEOUT;
    console.log(`Socket connected.`)
    if (this.onConnection()) { this.send(this.onConnection()) }
    this.flushOutgoingQueue();
  }

  socketClosed(e) {
    var timeout = Math.min(Socket.MAX_TIMEOUT, this.timeout);

    console.log(`Socket is down. Reconnecting in ${timeout}ms`, e.reason);
    setTimeout(this.connect.bind(this), timeout); // Incremental backoff
    this.timeout += this.timeout; // Exponential increase
  }

  connect() {
    this.socket = new WebSocket(this.address);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onopen = this.socketConnected.bind(this);
    this.socket.onclose = this.socketClosed.bind(this);
  }

  send(message) {
    try {
      this.socket.send(message);
      return true;
    } catch (e) {
      if (e.name == Socket.SOCKET_DOWN_ERR) {
        console.log(`Socket is down. Message queued.`)
        this.outgoing.push(message);
      }

      return false;
    }
  }

  flushOutgoingQueue() {
    for (var i = 0; i < this.outgoing.length; i++) {
      var message = this.outgoing.shift();
      var sentSuccessfully = this.send(message);
      if (!sentSuccessfully) { break; }
    }
  }
}
