module Main exposing (main)

import Browser
import Gorbachev
import Gorbachev.Pages as Pages
import Gorbachev.Pages.Report as Report
import Gorbachev.Ports as Ports
import Pages.Msg
import Shared.Msg
import Time
import UI.Menu


permanentSubscriptions : Gorbachev.Model -> Sub Gorbachev.Msg
permanentSubscriptions model =
    Sub.batch
        [ Ports.listen (Gorbachev.processReceivedSocket model)
        , Time.every 1000 (Gorbachev.SharedMsg << Shared.Msg.CheckSystemStatus) -- Every second
        , Time.every (60 * 1000) (Gorbachev.SharedMsg << Shared.Msg.CheckJwtExpiry) -- Every minute
        , Sub.map (Gorbachev.SharedMsg << Shared.Msg.NavMenuMsg) (UI.Menu.subscriptions model.shared.navMenu)
        ]


main : Program ( Maybe String, String ) Gorbachev.Model Gorbachev.Msg
main =
    Browser.application
        { init = Gorbachev.init
        , update = Gorbachev.update
        , view = Gorbachev.view
        , subscriptions =
            \model ->
                case model.page of
                    Pages.ReportPage _ _ reportPageModel ->
                        Sub.batch
                            [ Sub.map (Gorbachev.PageMsg << Pages.Msg.ReportPageMsg) (Report.subscriptions reportPageModel)
                            , permanentSubscriptions model
                            ]

                    _ ->
                        permanentSubscriptions model
        , onUrlRequest = Gorbachev.OnUrlRequest
        , onUrlChange = Gorbachev.OnUrlChange
        }
